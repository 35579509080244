@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-select-item-option{
  white-space: break-spaces;
  word-break: break-word;
}
.ant-select-dropdown .ant-select-item-option-content {
  white-space: normal;
}
.ant-select-selection-item{
  display: inline-block;
  white-space: break-spaces;
  word-break: break-word;
  width: 1px;
}

.divider{
  background-image: url('/public/images/waves.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
  width: 100%;
}

.custom-shape-divider-bottom-1688864165 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  border: none;
}

.custom-shape-divider-bottom-1688864165 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 118px;
}
@media (max-width: 768px) {
  .custom-shape-divider-bottom-1688864165 svg {
    width: fit-content;
  }
}

.custom-shape-divider-bottom-1688864165 .shape-fill {
  fill: #FFFFFF;
}

/* Divider 2 */
.custom-shape-divider-top-1688871244 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1688871244 svg {
  position: relative;
  display: block;
  width: calc(113% + 1.3px);
  height: 78px;
}

.custom-shape-divider-top-1688871244 .shape-fill {
  fill: #FFFFFF;
}

/* divider 3 */
.custom-shape-divider-bottom-1688871457 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1688871457 svg {
  position: relative;
  display: block;
  width: calc(113% + 1.3px);
  height: 78px;
}

.custom-shape-divider-bottom-1688871457 .shape-fill {
  fill: #FFFFFF;
}